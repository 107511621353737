//BRAND
export const name = 'Valhalla Wavelance';
export const company = 'Valhalla Wavelance Academy';
export const websiteURL = 'https://valhalla.wavelance.com';
export const serverURL = 'https://api.wavelance.com';
export const logo = 'https://firebasestorage.googleapis.com/v0/b/valhallataekni.appspot.com/o/logofinal.png?alt=media&token=9d6d73da-8055-4725-acf3-6abfe6282652';
export const razorpayEnabled = false;
export const paypalEnabled = true;
export const stripeEnabled = true;
export const paystackEnabled = false;
export const flutterwaveEnabled = false;

//PRICING :-

//FREE 
export const FreeType = 'Free Plan';
export const FreeCost = 0;
export const FreeTime = '';

//MONTHLY 
export const MonthType = 'Monthly Plan';
export const MonthCost = 1;
export const MonthTime = 'month';

//YEARLY 
export const YearType = 'Yearly Plan';
export const YearCost = 10;
export const YearTime = 'year';

//TESTIMONIAL2
export const review = "Valhalla SharedTown made course creation effortless. One click, and I get exactly what I need. It’s like having an expert by my side, saving time and improving quality. Plus, joining is easy with Stripe or PayPal.";
export const from = "Freja Lindholm";
export const profession = 'Student at Stockholm University';
export const photoURL = 'https://firebasestorage.googleapis.com/v0/b/valhallataekni.appspot.com/o/favlogo-01.png?alt=media&token=4d7829b3-10ae-4a08-bfd1-412c4a4a8269';

//PAYPAL
export const paypalPlanIdOne = "P-8T9161424D101043XM4BK3YQ";
export const paypalPlanIdTwo = "P-79F21569U2475594GM4BK5DQ";

//RAZORPAY
export const razorpayKeyId = "rzp_test_uqALjZHyTyuiOm";
export const razorpayPlanIdOne = "plan_NMvvtDfznbRp6V";
export const razorpayPlanIdTwo = "plan_NMRc9HARBQRLWA";

//STRIPE
export const stripePlanIdOne = "price_1Q6qfW09B8Yq4t9i9uQ2mqC4";
export const stripePlanIdTwo = "price_1Q6qhF09B8Yq4t9iswD033ng";

//PAYSTACK
export const paystackPlanIdOne = "PLN_ouqmm8eo6i2k9k8";
export const paystackPlanIdTwo = "PLN_1v1xqb8io9t5lis";
export const amountInZarOne = '170';
export const amountInZarTwo = '1871';

//FLUTTERWAVE
export const flutterwavePlanIdOne = "67960";
export const flutterwavePlanIdTwo = "67961";
export const flutterwavePublicKey = "FLWPUBK_TEST-6ee1faf6460ea587f510a024ac4c2b23-X";

//SOCIAL SIGNIN
export const googleClientId = "150644331076-flt197jvuqg9ohlf6q9rsjk3e3qomjd2.apps.googleusercontent.com";
export const facebookClientId = "8065327730213158";